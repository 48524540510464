import { FormFieldCheckbox, FormFieldSelect, FormFieldInput, FormFieldDate } from '/features/buildingBlocks/FormField'
import { useTranslate } from '/machinery/I18n'

export function Questionnaire({ fields, questionnaireQuestions }) {
  return (
    <>
      {questionnaireQuestions.map(x => (
        <Question
          key={x.id}
          question={x}
          field={fields[x.id]}
        />
      ))}
    </>
  )
}

export function PersonalInfoQuestions({ fields, personalInfoQuestions }) {
  return (
    <>
      <div><em>Individuals seeking employment with Rabobank or its subsidiaries are considered without regards to race, color, religion, national origin, age, gender, gender identity, ancestry, disability, veteran status or sexual orientation.</em></div>
      <div><em>Disclosure is voluntary and confidential.</em></div>
      <div><em>The information will only be used in accordance with the provisions of applicable federal laws, executive orders & regulations.</em></div>
      {personalInfoQuestions.map(x => (
        <Question
          key={x.id}
          question={x}
          field={fields[x.id]}
        />
      ))}
    </>
  )
}

function Question({ question, field }) {
  const { __ } = useTranslate()

  return (
    <>
      {question.type === 'checkbox' ? (
        <FormFieldCheckbox
          key={question.id}
          label={question.label}
          value={question.value}
          descriptionRaw={question.description}
          required={question.required}
          {...{ field }}
        />
      ) : question.type === 'select' ? (
        <FormFieldSelect
          key={question.id}
          label={question.label}
          description={question.description}
          required={question.required}
          options={question.options}
          placeholder={__`selecteer-een-optie`}
          {...{ field }}
        />
      ) : question.type === 'text' || question.type === 'number' ? (
        <FormFieldInput
          key={question.id}
          label={question.label}
          description={question.description}
          placeholder={question.additionalText}
          required={question.required}
          type={question.type}
          {...{ field }}
        />
      ) : question.type === 'date' ? (
        <FormFieldDate
          key={question.id}
          label={question.label}
          description={question.description}
          required={question.required}
          language={question.language || 'en-US'}
          {...{ field }}
        />
      ) : null}
    </>
  )
}
