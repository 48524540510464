import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { JobApplicationForm } from './JobApplicationForm'
import { FirebaseAppProviderWithAppCheck } from '/machinery/FirebaseAppProvider'
import { useClientConfig } from '/machinery/ClientConfig'

const queryClient = new QueryClient()

export function JobApplicationFormApp({ job, questionnaireQuestions, personalInfoQuestions, userOriginData }) {
  const config = useClientConfig()

  return (
    <FirebaseAppProviderWithAppCheck
      config={config.firebase}
      name={config.firebase.projectId}
      reCaptchaSiteKey={config.reCaptchaSiteKey}
    >
      <QueryClientProvider client={queryClient}>
        <JobApplicationForm {...{ job, questionnaireQuestions, personalInfoQuestions, userOriginData }} />
      </QueryClientProvider>
    </FirebaseAppProviderWithAppCheck>
  )
}
